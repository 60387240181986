import { createSlice } from '@reduxjs/toolkit';

const initialState: any = null;

const lastSincronizationDataSlice = createSlice({
    name: 'lastSincronizationDataReducer',
    initialState,
    reducers: {
        setLastSincronizationData(state, action) {
            const data = action.payload;

            state = data;

            return data;
        }
    }
});

export default lastSincronizationDataSlice.reducer;

export const { setLastSincronizationData } = lastSincronizationDataSlice.actions;
