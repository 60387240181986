/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';
import maskMoney from '../../utils/maskMoney';

const initialState: any = null;

const reportChartSeriesDataSlice = createSlice({
    name: 'reportChartSeriesDataReducer',
    initialState,
    reducers: {
        setReportChartSeriesData(state: any, action: any) {
            const response = action.payload?.response;

            if (response.statusCode === 401) {
                state = null;
                localStorage.clear();
                location.reload();
            }
            if (response.statusCode === 200) {
                const data = response.data;
                const formattedMonths = data.months.map((e: any) => `${e.split('/')[0]}/${e.split('/')[1].slice(2)}`);
                const series = data.transactions.map((e: any) => ({
                    ...e,
                    total: `R$ ${maskMoney(e.data.reduce((acc: any, cur: any) => acc + cur, 0).toFixed(2))}`
                }));

                state = {
                    xAxisCategories: formattedMonths,
                    series
                };
            }
            return state;
        }
    }
});

export default reportChartSeriesDataSlice.reducer;

export const { setReportChartSeriesData } = reportChartSeriesDataSlice.actions;
