/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';
import maskMoney from '../../utils/maskMoney';
import formattOrderDate from '../../utils/formattOrderDate';

const initialState: any = null;

const ordersDetailedDataSlice = createSlice({
    name: 'ordersDetailedDataReducer',
    initialState,
    reducers: {
        setOrdersDetailedData(state, action) {
            const response = action.payload?.response;

            if (response.statusCode === 401) {
                state = null;
                localStorage.clear();
                location.reload();
            }

            if (!response.data) {
                return state;
            }

            const handleStatus = (status: any) => {
                switch (status.toLowerCase()) {
                    case 'pending':
                        return 0;
                    case 'success':
                        return 1;
                    case 'error':
                        return 2;
                    case 'refund':
                        return 3;
                }
            };

            const formattPaymentMethod = (paymentMethod: any) => {
                switch (paymentMethod.toLowerCase()) {
                    case 'creditcard':
                        return 'Cartão de crédito';
                    case 'boleto':
                        return 'Boleto';
                    case 'pix':
                        return 'Pix';
                    default:
                        return 'Não identificado';
                }
            };

            if (response.statusCode === 200) {
                const data = response.data;
                const newRows: any = [];

                data.forEach((paymentData: any) => {
                    const payment = paymentData.payment[0] || paymentData.payment;
                    const store = paymentData.store;
                    const refund_date = payment.refund_date ? formattOrderDate(payment.refund_date) : null;

                    newRows.push({
                        id: payment.id,
                        paymentId: payment.payment_id,
                        orderId: payment.order_id,
                        storeId: payment.store_id,
                        integrationName: payment.integration_name,
                        installments: payment?.installments,
                        paymentMethod: formattPaymentMethod(payment.payment_method),
                        refund_date,
                        storeName: store.name,
                        storeURL: store.store_url,
                        status: handleStatus(payment.status),
                        statusDescription: payment?.refusal_message ? payment?.refusal_message : payment.status_description,
                        subtotal: `R$ ${maskMoney(payment.subtotal)}`,
                        discount: `R$ ${maskMoney(payment.discount)}`,
                        shipping: `R$ ${maskMoney(payment.shipping)}`,
                        total: `R$ ${maskMoney(payment.total)}`,
                        date: formattOrderDate(payment.created_at)
                    });
                });

                state = newRows;
            }
            return state;
        }
    }
});

export default ordersDetailedDataSlice.reducer;

export const { setOrdersDetailedData } = ordersDetailedDataSlice.actions;
