/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';
import maskMoney from '../../utils/maskMoney';
import handleMonth from '../../utils/handleMonth';

const initialState: any = null;

const salesColumnChartSeriesDataSlice = createSlice({
    name: 'salesColumnChartSeriesDataReducer',
    initialState,
    reducers: {
        setSalesColumnChartSeriesData(state: any, action: any) {
            const periodSalesColumnChart = action.payload.periodSalesColumnChart;
            const response = action.payload?.response;

            if (response.statusCode === 401) {
                state = null;
                localStorage.clear();
                location.reload();
            }

            if (response.statusCode === 200) {
                let data = response.data;

                const yearMonthArr = data
                    .map((e: any) => ({
                        month: e.month,
                        year: e.year
                    }))
                    .filter((e: any, i: any, arr: any) => arr.map((e: any) => JSON.stringify(e)).indexOf(JSON.stringify(e)) === i);

                yearMonthArr.forEach((ym: any) => {
                    const filteredBySuccess = data.filter((e: any) => {
                        return e.status === 'success' && e.month === ym.month && e.year === ym.year;
                    });

                    if (filteredBySuccess.length === 0) {
                        data.push({
                            total: '0',
                            status: 'success',
                            month: ym.month,
                            year: ym.year
                        });
                    }
                    const filteredByPending = data.filter((e: any) => {
                        return e.status === 'pending' && e.month === ym.month && e.year === ym.year;
                    });

                    if (filteredByPending.length === 0) {
                        data.push({
                            total: '0',
                            status: 'pending',
                            month: ym.month,
                            year: ym.year
                        });
                    }

                    const filteredByError = data.filter((e: any) => {
                        return e.status === 'error' && e.month === ym.month && e.year === ym.year;
                    });

                    if (filteredByError.length === 0) {
                        data.push({
                            total: '0',
                            status: 'error',
                            month: ym.month,
                            year: ym.year
                        });
                    }

                    const filteredByRefund = data.filter((e: any) => {
                        return e.status === 'refund' && e.month === ym.month && e.year === ym.year;
                    });

                    if (filteredByRefund.length === 0) {
                        data.push({
                            total: '0',
                            status: 'refund',
                            month: ym.month,
                            year: ym.year
                        });
                    }
                });

                data = response.data.sort((a: any, b: any) => {
                    if (Number(a.year) > Number(b.year)) {
                        return 1;
                    }
                    if (Number(a.year) === Number(b.year)) {
                        if (a.month > b.month) {
                            return 1;
                        }
                    }

                    return -1;
                });

                const xAxisCategories = data
                    .map((e: any) => `${handleMonth(String(e.month))}/${String(e?.year)?.slice(2)}`)
                    .filter((e: any, i: any, arr: any) => arr.indexOf(e) === i);
                const successData = data.filter((e: any) => e.status === 'success').map((e: any) => Number(e.total));
                const pendingData = data.filter((e: any) => e.status === 'pending').map((e: any) => Number(e.total));
                const errorData = data.filter((e: any) => e.status === 'error').map((e: any) => Number(e.total));
                const refundData = data.filter((e: any) => e.status === 'refund').map((e: any) => Number(e.total));

                const totalSales = maskMoney(
                    data
                        .reduce((acum: any, curr: any) => acum + Number(curr.total), 0)
                        .toFixed(2)
                        .toString()
                );

                state = {
                    xAxisCategories:
                        periodSalesColumnChart === 'week'
                            ? ['Esta semana']
                            : periodSalesColumnChart === 'today'
                              ? ['Este dia']
                              : periodSalesColumnChart === 'month'
                                ? ['Este mês']
                                : xAxisCategories,
                    totalSales: totalSales || '0,00',
                    series: [
                        {
                            name: 'Sucesso',
                            data: successData?.length
                                ? periodSalesColumnChart === 'week'
                                    ? [successData.reduce((acum: any, curr: any) => acum + curr, 0)]
                                    : successData
                                : [0]
                        },
                        {
                            name: 'Pendentes',
                            data: pendingData?.length
                                ? periodSalesColumnChart === 'week'
                                    ? [pendingData.reduce((acum: any, curr: any) => acum + curr, 0)]
                                    : pendingData
                                : [0]
                        },
                        {
                            name: 'Falha',
                            data: errorData?.length
                                ? periodSalesColumnChart === 'week'
                                    ? [errorData.reduce((acum: any, curr: any) => acum + curr, 0)]
                                    : errorData
                                : [0]
                        },
                        {
                            name: 'Cancelados',
                            data: refundData?.length
                                ? periodSalesColumnChart === 'week'
                                    ? [refundData.reduce((acum: any, curr: any) => acum + curr, 0)]
                                    : refundData
                                : [0]
                        }
                    ]
                };
            }
            return state;
        }
    }
});

export default salesColumnChartSeriesDataSlice.reducer;

export const { setSalesColumnChartSeriesData } = salesColumnChartSeriesDataSlice.actions;
