/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';
import maskMoney from '../../utils/maskMoney';

const initialState: any = null;

const cardsDashboardDataSlice = createSlice({
    name: 'cardsDashboardDataReducer',
    initialState,
    reducers: {
        setCardsDashboardData(state: any, action) {
            const response = action.payload?.response;

            if (response.statusCode === 401) {
                state = null;
                localStorage.clear();
                location.reload();
            }
            if (response.statusCode === 200) {
                const totals = response.data.totals[0];

                state = [
                    {
                        title: 'Total',
                        value: `R$ ${maskMoney(totals.total)}`,
                        percentage: 100,
                        color: '#1990FF',
                        backgroundColor: '#DAEDFF',
                        total: totals.count_total
                    },
                    {
                        title: 'Total com Sucesso',
                        value: `R$ ${maskMoney(totals.total_success)}`,
                        percentage: ((Number(totals.total_success) / Number(totals.total)) * 100).toFixed(0),
                        color: '#52C41A',
                        backgroundColor: '#ECFDDA',
                        total: totals.count_success
                    },
                    {
                        title: 'Total Pendentes',
                        value: `R$ ${maskMoney(totals.total_pending)}`,
                        percentage: ((Number(totals.total_pending) / Number(totals.total)) * 100).toFixed(0),
                        color: '#FAAD14',
                        backgroundColor: '#FFF6E4',
                        total: totals.count_pending
                    },
                    {
                        title: 'Total com Falha',
                        value: `R$ ${maskMoney(totals.total_error)}`,
                        percentage: ((Number(totals.total_error) / Number(totals.total)) * 100).toFixed(0),
                        color: '#FF4D4F',
                        backgroundColor: '#FFEEEE',
                        total: totals.count_error
                    },
                    {
                        title: 'Total Canceladas',
                        value: `R$ ${maskMoney(totals.total_refund)}`,
                        percentage: ((Number(totals.total_refund) / Number(totals.total)) * 100).toFixed(0),
                        color: '#FAAD14',
                        backgroundColor: '#FFF6E4',
                        total: totals.count_refund
                    }
                ];
            }
            return state;
        }
    }
});

export default cardsDashboardDataSlice.reducer;

export const { setCardsDashboardData } = cardsDashboardDataSlice.actions;
