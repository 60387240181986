/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';
import maskMoney from '../../utils/maskMoney';

const initialState: any = null;

const barChartSeriesDataSlice = createSlice({
    name: 'barChartSeriesDataReducer',
    initialState,
    reducers: {
        setBarChartSeriesData(state: any, action: any) {
            const response = action.payload?.response;

            if (response.statusCode === 401) {
                state = null;
                localStorage.clear();
                location.reload();
            }
            if (response.statusCode === 200) {
                const data = response.data;
                const xAxisCategories = data.map((e: any) => e.day);
                const totals = data.map((e: any) => Number(e.total));
                const totalSales = maskMoney(
                    totals
                        .reduce((acum: any, curr: any) => acum + Number(curr), 0)
                        .toFixed(2)
                        .toString()
                );

                state = {
                    xAxisCategories: xAxisCategories || [],
                    totalSales,
                    series: [{ name: 'Total', data: totals || [] }]
                };
            }
            return state;
        }
    }
});

export default barChartSeriesDataSlice.reducer;

export const { setBarChartSeriesData } = barChartSeriesDataSlice.actions;
