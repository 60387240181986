import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from '../../../../../menu-items';
import support from '../../../../../menu-items/support';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const user = useAppSelector((state) => state.user);
    const [navGroups, setNavGroups] = useState<any>(null);

    useEffect(() => {
        const selectedCompany = user?.selectedCompany;

        if (selectedCompany) {
            const items = [...menuItem.items, support({ selectedCompany })];

            setNavGroups(
                items.map((item) => {
                    switch (item.type) {
                        case 'group':
                            return <NavGroup key={item.id} item={item} />;
                        default:
                            return (
                                <Typography key={item.id} variant="h6" color="error" align="center">
                                    Fix - Navigation Group
                                </Typography>
                            );
                    }
                })
            );
        }
    }, [user]);

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
