/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';
import handleMonth from '../../utils/handleMonth';

const initialState: any = null;

const incomeChartSeriesDataSlice = createSlice({
    name: 'incomeChartSeriesDataReducer',
    initialState,
    reducers: {
        setIncomeChartSeriesData(state: any, action) {
            const response = action.payload?.response;

            if (response.statusCode === 401) {
                state = null;
                localStorage.clear();
                location.reload();
            }
            if (response.statusCode === 200) {
                const data = response.data;
                const formattedData = {
                    ...data,
                    month: { ...data.month, label: data.month.label.map((e: any) => handleMonth(e.split('/')[0])) }
                };

                state = formattedData;
            }
            return state;
        }
    }
});

export default incomeChartSeriesDataSlice.reducer;

export const { setIncomeChartSeriesData } = incomeChartSeriesDataSlice.actions;
