/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';
import maskMoney from '../../utils/maskMoney';
import formattOrderDate from '../../utils/formattOrderDate';

const initialState: any = null;

const ordersDataSlice = createSlice({
    name: 'ordersDataReducer',
    initialState,
    reducers: {
        setOrdersData(state, action) {
            const response = action.payload?.response;

            if (response.statusCode === 401) {
                state = null;
                localStorage.clear();
                location.reload();
            }

            const handleStatus = (status: any) => {
                switch (status.toLowerCase()) {
                    case 'pending':
                        return 0;
                    case 'success':
                        return 1;
                    case 'error':
                        return 2;
                    case 'refund':
                        return 3;
                }
            };

            const formattPaymentMethod = (paymentMethod: any) => {
                switch (paymentMethod.toLowerCase()) {
                    case 'creditcard':
                        return 'Cartão de crédito';
                    case 'boleto':
                        return 'Boleto';
                    case 'pix':
                        return 'Pix';
                    default:
                        return 'Não identificado';
                }
            };

            if (response.statusCode === 200) {
                const data = response.data;
                const newRows: any = [];

                data.forEach((payment: any) => {
                    newRows.push({
                        paymentId: payment.payment_id,
                        orderId: payment.order_id,
                        storeId: payment.store_id,
                        integrationName: payment.integration_name,
                        installments: payment?.installments,
                        paymentMethod: formattPaymentMethod(payment.payment_method),
                        shipping: `R$ ${maskMoney(payment.shipping)}`,
                        storeName: payment.store_name,
                        discount: `R$ ${maskMoney(payment.discount)}`,
                        subtotal: `R$ ${maskMoney(payment.subtotal)}`,
                        status: handleStatus(payment.status),
                        statusDescription: payment.status_description,
                        total: `R$ ${maskMoney(payment.total)}`,
                        date: formattOrderDate(payment.created_at)
                    });
                });

                state = newRows;
            }
            return state;
        }
    }
});

export default ordersDataSlice.reducer;

export const { setOrdersData } = ordersDataSlice.actions;
