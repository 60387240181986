const maskMoney = (rawMoney: any) => {
    if (!rawMoney.includes('.')) {
        rawMoney += '.00';
    }

    if (rawMoney.split('.')[1].length === 0) {
        rawMoney += '00';
    }

    if (rawMoney.split('.')[1].length === 1) {
        rawMoney += '0';
    }

    let formattedMoney = rawMoney.replace(/\D/g, '');

    if (rawMoney.length < 7) {
        formattedMoney = formattedMoney.replace(/^(\d{0,3})(\d{2})/, '$1,$2');
    }

    if (rawMoney.length === 7) {
        formattedMoney =
            formattedMoney
                .replace(/\D/g, '')
                .slice(0, 3)
                .replace(/^(\d{1})(\d{2})/, '$1,$2') + 'K';
    }

    if (rawMoney.length === 8) {
        formattedMoney =
            formattedMoney
                .replace(/\D/g, '')
                .slice(0, 4)
                .replace(/^(\d{2})(\d{2})/, '$1,$2') + 'K';
    }

    if (rawMoney.length === 9) {
        formattedMoney =
            formattedMoney
                .replace(/\D/g, '')
                .slice(0, 5)
                .replace(/^(\d{3})(\d{2})/, '$1,$2') + 'K';
    }

    if (rawMoney.length === 10) {
        formattedMoney =
            formattedMoney
                .replace(/\D/g, '')
                .slice(0, 3)
                .replace(/^(\d{1})(\d{2})/, '$1,$2') + 'M';
    }

    if (rawMoney.length === 11) {
        formattedMoney =
            formattedMoney
                .replace(/\D/g, '')
                .slice(0, 4)
                .replace(/^(\d{2})(\d{2})/, '$1,$2') + 'M';
    }

    if (rawMoney.length === 12) {
        formattedMoney =
            formattedMoney
                .replace(/\D/g, '')
                .slice(0, 5)
                .replace(/^(\d{3})(\d{2})/, '$1,$2') + 'M';
    }

    return formattedMoney;
};

export default maskMoney;
