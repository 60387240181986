/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';
import maskMoney from '../../utils/maskMoney';

const initialState: any = null;

const paymentsSlice = createSlice({
    name: 'paymentsReducer',
    initialState,
    reducers: {
        setPayments(state, action) {
            const response = action.payload?.response;

            if (response.statusCode === 401) {
                state = null;
                localStorage.clear();
                location.reload();
            }

            if (response.statusCode === 200) {
                const data = response.data.data;
                const formattedData = data.map((e: any) => {
                    const date = new Date(e.created_at);
                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();
                    const hour = e.hour;

                    return {
                        id: e.payment_id,
                        order_id: e.order_id,
                        total: `R$ ${maskMoney(e.total)}`,
                        date: `${day}/${month}/${year} às ${hour}h`
                    };
                });

                state = formattedData;
            }
            return state;
        }
    }
});

export default paymentsSlice.reducer;

export const { setPayments } = paymentsSlice.actions;
