import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'd-client-ip': JSON.parse(localStorage.getItem('d-client-ip') as string),
        'x-forwarded-for': JSON.parse(localStorage.getItem('d-client-ip') as string)
    }
});

export default api;
