const formattOrderDate = (stringDate: any) => {
    const year = stringDate.split('-')[0];
    const month = stringDate.split('-')[1];
    const day = stringDate.split('T')[0].split('-')[2];

    const hour = `${stringDate.split('T')[1].split(':')[0]}:${stringDate.split('T')[1].split(':')[1]}`;

    return `${day}/${month}/${year} às ${hour}h`;
};

export default formattOrderDate;
