// material-ui
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { setLoggedUser } from '../../../../store/reducers/user';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';

// project import
// import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';
import { useMemo } from 'react';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const matchesXs = useMediaQuery<any>((theme) => theme?.breakpoints?.down('md'));

    const isChangeCompanyButtonVisible = useMemo(
        () => (user.companies.length > 1 ? true : user?.companies[0]?.integrations?.length > 1),
        [user.companies]
    );

    const handleChangeCompany = () => {
        dispatch(setLoggedUser({ ...user, selectedCompany: '', selectedIntegration: '' }));
    };

    return (
        <>
            {/* {!matchesXs && <Search />} */}
            <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }} />
            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

            {isChangeCompanyButtonVisible && (
                <Button sx={{ width: 300 }} variant="contained" onClick={handleChangeCompany}>
                    Mudar Empresa
                </Button>
            )}
            {user.selectedCompany && (
                <Typography sx={{ width: 350, marginLeft: 2 }} variant="subtitle1" color="textPrimary">
                    Empresa: {user.selectedCompany.name}
                </Typography>
            )}
            {user.selectedIntegration && (
                <Typography sx={{ width: 350, marginLeft: 2 }} variant="subtitle1" color="textPrimary">
                    Integração: {user.selectedIntegration.name}
                </Typography>
            )}
            {false && <Notification />}
            {!matchesXs && <Profile />}
            {matchesXs && <MobileSection />}
        </>
    );
};

export default HeaderContent;
