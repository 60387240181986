// assets
import { ChromeOutlined, QuestionOutlined } from '@ant-design/icons';

// icons
const icons = {
    ChromeOutlined,
    QuestionOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = ({ selectedCompany }: any) => ({
    id: 'support',
    title: 'Suporte',
    type: 'group',
    children: [
        {
            id: 'documentation',
            title: `Suporte ${selectedCompany.name}`,
            type: 'item',
            url: selectedCompany.supportUrl,
            icon: icons.QuestionOutlined,
            external: true,
            target: true
        }
    ]
});

export default support;
