/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = null;

const reportsDataSlice = createSlice({
    name: 'reportsDataReducer',
    initialState,
    reducers: {
        setReportsData(state, action) {
            const response = action.payload?.response;

            if (response.statusCode === 401) {
                state = null;
                localStorage.clear();
                location.reload();
            }

            const handlePaymentMethod = (paymentMethods: any) => {
                if (!paymentMethods?.length) {
                    return '';
                }

                const treatedPaymentMethod = paymentMethods
                    .map((e: any) => {
                        switch (e) {
                            case 'CreditCard':
                                return 'Cartão de crédito';
                            default:
                                return e;
                        }
                    })
                    .join(', ');

                return treatedPaymentMethod;
            };

            const handleStatus = (status: any) => {
                if (!status?.length) {
                    return '';
                }

                const treatedStatus = status
                    .map((e: any) => {
                        switch (e) {
                            case 'success':
                                return 'Aprovadas';
                            case 'pending':
                                return 'Pendentes';
                            case 'error':
                                return 'Rejeitadas';
                            case 'refund':
                                return 'Canceladas';
                            default:
                                return e;
                        }
                    })
                    .join(', ');

                return treatedStatus;
            };

            if (response.statusCode === 200) {
                let newData = response.data.map((e: any) => ({
                    ...e,
                    instructions: e.instructions ? JSON.parse(e.instructions) : e.instructions
                }));
                newData = newData.map((e: any) => ({
                    ...e,
                    instructions: {
                        ...e.instructions,
                        paymentMethod: handlePaymentMethod(e.instructions.paymentMethod),
                        status: handleStatus(e.instructions.status)
                    }
                }));

                state = newData;
            }
            return state;
        }
    }
});

export default reportsDataSlice.reducer;

export const { setReportsData } = reportsDataSlice.actions;
