import api from '..';
import { PostPaymentSynchronizeRequest, PostPaymentSynchronizeResponse } from './types';
import { ErrorData } from '../typesError';

const paymentsSynchronize = async ({
    accessToken,
    companyId
}: PostPaymentSynchronizeRequest): Promise<PostPaymentSynchronizeResponse | ErrorData> => {
    try {
        const result = await api.post(
            `/payments/synchronize/${companyId}`,
            {},
            {
                headers: { Authorization: `Bearer ${accessToken}` }
            }
        );

        return { statusCode: result.status, data: result.data };
    } catch (error: any) {
        const message = error?.response?.data?.response;
        const statusCode = error?.response?.status;

        return { statusCode: statusCode || 500, message: message || 'Erro no servidor' };
    }
};

export default paymentsSynchronize;
