import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard')));
const DashboardOrders = Loadable(lazy(() => import('../pages/dashboard/orders')));
const DashboardReports = Loadable(lazy(() => import('../pages/dashboard/reports')));
const DashboardHistory = Loadable(lazy(() => import('../pages/dashboard/history')));
const DashboardStores = Loadable(lazy(() => import('../pages/dashboard/stores')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                },
                {
                    path: 'orders',
                    element: <DashboardOrders />
                },
                {
                    path: 'reports',
                    element: <DashboardReports />
                },
                {
                    path: 'history',
                    element: <DashboardHistory />
                },
                {
                    path: 'stores',
                    element: <DashboardStores />
                }
            ]
        }
    ]
};

export default MainRoutes;
