// assets
import { DashboardOutlined, DatabaseOutlined, FileExcelOutlined, TableOutlined } from '@ant-design/icons';

// icons
const icons = {
    DashboardOutlined,
    DatabaseOutlined,
    FileExcelOutlined,
    TableOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: 'Navegação',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.DashboardOutlined,
            breadcrumbs: false
        },
        {
            id: 'reports',
            title: 'Relatório',
            type: 'item',
            url: '/dashboard/reports',
            icon: icons.FileExcelOutlined,
            breadcrumbs: false
        },
        {
            id: 'orders',
            title: 'Ordens',
            type: 'item',
            url: '/dashboard/orders',
            icon: icons.TableOutlined,
            breadcrumbs: false
        },
        {
            id: 'history',
            title: 'Histórico de eventos',
            type: 'item',
            url: '/dashboard/history',
            icon: icons.TableOutlined,
            breadcrumbs: false
        },
        {
            id: 'stores',
            title: 'Lista de lojas',
            type: 'item',
            url: '/dashboard/stores',
            icon: icons.DatabaseOutlined,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
