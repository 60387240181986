// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import user from './user';
import paymentsReducer from './payments';
import salesColumnChartSeriesDataReducer from './salesColumnChartSeriesDataReducer';
import cardsDashboardDataReducer from './cardsDashboardDataReducer';
import dateRangeReducer from './dateRangeReducer';
import barChartSeriesDataReducer from './barChartSeriesDataReducer';
import reportChartSeriesDataReducer from './reportChartSeriesDataReducer';
import incomeChartSeriesDataReducer from './incomeChartSeriesDataReducer';
import ordersDataReducer from './ordersDataReducer';
import ordersDetailedDataReducer from './ordersDetailedDataReducer';
import integrationStatisticsDataReducer from './integrationStatisticsDataReducer';
import lastSincronizationDataReducer from './lastSincronizationDataReducer';
import reportsDataReducer from './reportsDataReducer';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    menu,
    user,
    paymentsReducer,
    salesColumnChartSeriesDataReducer,
    cardsDashboardDataReducer,
    dateRangeReducer,
    barChartSeriesDataReducer,
    reportChartSeriesDataReducer,
    incomeChartSeriesDataReducer,
    ordersDataReducer,
    ordersDetailedDataReducer,
    integrationStatisticsDataReducer,
    lastSincronizationDataReducer,
    reportsDataReducer
});

export default reducers;
