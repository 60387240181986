import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';
import { Navigate } from 'react-router-dom';

// render - login
const AuthLogin = Loadable(lazy(() => import('../pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('../pages/authentication/Register')));
const ForgotPassword = Loadable(lazy(() => import('../pages/authentication/ForgotPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/authentication/NewPassword')));
const AccountConfirmed = Loadable(lazy(() => import('../pages/authentication/AccountConfirmed')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '*',
            element: <Navigate to="/login" />
        },
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: 'register',
            element: <AuthRegister />
        },
        {
            path: 'forgot-password',
            element: <ForgotPassword />
        },
        {
            path: 'users/password-reset/:token',
            element: <NewPassword />
        },
        {
            path: 'users/confirmation',
            element: <AccountConfirmed />
        }
    ]
};

export default LoginRoutes;
