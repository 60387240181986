/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = null;

const integrationStatisticsDataSlice = createSlice({
    name: 'integrationStatisticsDataReducer',
    initialState,
    reducers: {
        setIntegrationStatisticsData(state, action) {
            const response = action.payload?.response;

            if (response.statusCode === 401) {
                state = null;
                localStorage.clear();
                location.reload();
            }
            if (response.statusCode === 200) {
                state = response.data;
            }
            return state;
        }
    }
});

export default integrationStatisticsDataSlice.reducer;

export const { setIntegrationStatisticsData } = integrationStatisticsDataSlice.actions;
