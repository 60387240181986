/* eslint-disable no-restricted-globals */
import { useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import paymentsSynchronize from '../../../../../services/api/paymentsSynchronize/index';

// assets
import {
    CommentOutlined,
    LockOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    UserOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = () => {
    const user = useAppSelector((state) => state.user);
    const theme = useTheme();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleListItemClick = async (event: any, index: any) => {
        setSelectedIndex(index);

        if (window) {
            index === 0 && window.open(user?.selectedCompany?.supportUrl, '_blank')?.focus();
        }

        if (index === 5) {
            const response = await paymentsSynchronize({ accessToken: user.access_token, companyId: user.selectedCompany.id });
            if (response.statusCode === 201) {
                location.reload();
            }
        }
    };

    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
            {user?.selectedCompany && (
                <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
                    <ListItemIcon>
                        <QuestionCircleOutlined />
                    </ListItemIcon>
                    <ListItemText primary={`Suporte ${user.selectedCompany.name}`} />
                </ListItemButton>
            )}
            {false && (
                <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
                    <ListItemIcon>
                        <UserOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Account Settings" />
                </ListItemButton>
            )}
            {false && (
                <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
                    <ListItemIcon>
                        <LockOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Privacy Center" />
                </ListItemButton>
            )}
            {false && (
                <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
                    <ListItemIcon>
                        <CommentOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Feedback" />
                </ListItemButton>
            )}
            {false && (
                <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
                    <ListItemIcon>
                        <UnorderedListOutlined />
                    </ListItemIcon>
                    <ListItemText primary="History" />
                </ListItemButton>
            )}
            {user?.selectedCompany && (
                <ListItemButton selected={selectedIndex === 5} onClick={(event) => handleListItemClick(event, 5)}>
                    <ListItemIcon>
                        <ReloadOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Atualizar dados" />
                </ListItemButton>
            )}
        </List>
    );
};

export default SettingTab;
