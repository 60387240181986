import { createSlice } from '@reduxjs/toolkit';

const initialState: any = null;

const dateRangeSlice = createSlice({
    name: 'dateRangeReducer',
    initialState,
    reducers: {
        setDateRange(state: any, action) {
            state = action.payload?.dateRange;
            return state;
        }
    }
});

export default dateRangeSlice.reducer;

export const { setDateRange } = dateRangeSlice.actions;
