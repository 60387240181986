/* eslint-disable no-restricted-globals */
import { createSlice } from '@reduxjs/toolkit';

interface UserInterface {
    id: string;
    name: string;
    email: string;
    role: string;
    access_token: string;
    companies: any[];
    selectedCompany: any;
    selectedIntegration: any;
}

const initialState: UserInterface = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') as string)
    : {
          id: '',
          name: '',
          email: '',
          role: '',
          access_token: '',
          companies: [],
          selectedCompany: null,
          selectedIntegration: null
      };

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedUser(state, action) {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.role = action.payload.role;
            state.access_token = action.payload.access_token;
            state.companies = action.payload.companies;
            state.selectedCompany = action.payload?.selectedCompany;
            state.selectedIntegration = action.payload?.selectedIntegration;
            localStorage.setItem('user', JSON.stringify(action.payload));
        },
        logout(state) {
            localStorage.clear();
            state = initialState;
            location.reload();
            return state;
        }
    }
});

export default user.reducer;

export const { setLoggedUser, logout } = user.actions;
